import React, { useContext } from 'react';
import { Container, Card, Title } from '@tymate/elise/ui';
import ProfileForm from '@tymate/elise/components/ProfileForm';
import { AuthContext } from 'contexts';
import { updateMe } from 'api/auth';
import { pickBy, omit } from 'lodash';
import { useAppState } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';

const Profile = () => {
  const { user, accessToken } = useContext(AuthContext);
  const { notify } = useAppState();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(updateMe, {
    onSuccess: () => {
      notify('Profil mis à jour.');
      queryClient.invalidateQueries(['user', accessToken]);
    },
    onError: () => {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    },
  });

  const handleUpdateMe = async values => {
    await mutateAsync(omit(pickBy(values), 'fakePassword'));
  };

  return (
    <Container variant="main" size="narrow">
      <Title>Mon profil</Title>
      <Card>
        <ProfileForm user={user} onSubmit={handleUpdateMe} />
      </Card>
    </Container>
  );
};
export default Profile;
