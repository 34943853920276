import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  NavLink,
  useParams,
  useLocation,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import ProductionSite from './ProductionSite';
import CreateProductionSite from './CreateProductionSite';
import EditProductionSite from './EditProductionSite';
import { Container, ButtonReset } from '@tymate/elise/ui';
import { getProductionSites } from 'api/productionSites';
import { Loading } from '@tymate/elise/components';
import UserDetail from 'containers/Franchises/Franchise/UserDetail';

const Tabs = styled(Stack).attrs({ alignY: 'center' })`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing()};
`;

const Tab = styled.li`
  display: block;
`;

const TabNavButton = styled(ButtonReset)`
  display: block;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  appearance: none;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.375)}
    ${({ theme }) => theme.spacing()};
  cursor: pointer;
  width: max-content;
  text-align: center;

  &.active {
    color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primaryLighten};
  }

  ${({ variant, theme }) =>
    variant === 'add' &&
    `
    color: ${theme.primary};
    border-color: transparent;
    background-color: ${theme.primaryLighten};
  `}
`;

const ColorPoint = styled.div`
  width: 8px;
  height: 8px;

  border-radius: 4px;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.success : theme.error};
  padding-left: ${({ theme }) => theme.spacing(0.25)};
`;

const ProductionSites = () => {
  const { pathname } = useLocation();
  const { franchiseId } = useParams();

  const { data: response, isLoading } = useQuery(
    ['productionSites', franchiseId],
    () =>
      getProductionSites({
        franchiseId,
        params: {
          'page[number]': 1,
          'page[size]': 10,
        },
      }),
  );

  const productionSites = response?.data || [];

  if (isLoading) {
    return <Loading />;
  }

  if (
    productionSites?.length > 0 &&
    pathname === `/franchises/${franchiseId}/sites-de-productions`
  ) {
    return (
      <Redirect
        to={`/franchises/${franchiseId}/sites-de-productions/${productionSites[0].id}`}
      />
    );
  }

  return (
    <Container variant="main">
      <Tabs>
        {productionSites.map(({ id, name, open }) => (
          <Tab key={id}>
            <TabNavButton
              to={`/franchises/${franchiseId}/sites-de-productions/${id}`}
              as={NavLink}
            >
              <Stack alignY="center" gutterSize={0.375}>
                <ColorPoint isOpen={open} />
                <div>{name}</div>
              </Stack>
            </TabNavButton>
          </Tab>
        ))}
        <Tab>
          <TabNavButton
            to={`/franchises/${franchiseId}/sites-de-productions/creer`}
            as={NavLink}
            variant="add"
          >
            + Ajouter un site
          </TabNavButton>
        </Tab>
      </Tabs>
      <Switch>
        <Route
          path="/franchises/:franchiseId/sites-de-productions/:productionSiteId"
          component={ProductionSite}
        />
      </Switch>
      <Switch>
        <Route
          path="/franchises/:franchiseId/sites-de-productions/:productionSiteId/modifier"
          component={EditProductionSite}
        />
        <Route
          path="/franchises/:franchiseId/sites-de-productions/creer"
          exact
          component={CreateProductionSite}
        />
        <Route
          path="/franchises/:franchiseId/sites-de-productions/:productionSiteId/users/:userId"
          component={UserDetail}
        />
      </Switch>
    </Container>
  );
};

export default ProductionSites;
