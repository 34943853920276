import React from 'react';
import { DataTable } from '@tymate/elise/components';
import { useSearchParams } from '@tymate/elise/hooks';
import { Title } from '@tymate/elise/ui';
import { formatSort } from '@tymate/elise/utils';
import { Pagination } from '@tymate/elise/components/New';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getProductionSiteVehicles } from 'api/productionSites';
import { Stack } from '@tymate/margaret';

const Vehicles = () => {
  const { franchiseId, productionSiteId } = useParams();
  const [{ page = 1, sort }] = useSearchParams();

  const { data: vehiclesData, isLoading } = useQuery(
    ['productionSiteVehicles', franchiseId, productionSiteId, sort, page],
    () => {
      return getProductionSiteVehicles({
        franchiseId,
        params: {
          productionSiteId: productionSiteId,
          'page[number]': page,
          'page[size]': 15,
          sort: formatSort(sort),
        },
      });
    },
  );

  const vehicles = vehiclesData?.data || [];

  const headings = [
    {
      slug: 'licensePlate',
      label: 'Immatriculation',
    },
    {
      slug: 'kind',
      label: 'Type',
      cannotBeReordered: true,
    },
    {
      slug: 'loadingType',
      label: 'Chargement',
      cannotBeReordered: true,
    },
    {
      slug: 'fuelType',
      label: 'Carburant',
      cannotBeReordered: true,
    },
    {
      slug: 'height',
      label: 'Charge (Kg)',
    },
    {
      slug: 'volume',
      label: 'V (m3)',
    },
    {
      slug: 'length',
      label: 'H (m)',
    },
    {
      slug: 'critair',
      label: "Crit'air",
    },
  ];

  const data = vehicles.map(
    ({
      licensePlate,
      kind,
      loadingType,
      fuelType,
      height,
      volume,
      length,
      critair,
    }) => ({
      licensePlate: {
        value: licensePlate,
      },
      kind: { value: kind },
      loadingType: { value: loadingType },
      fuelType: { value: fuelType },
      height: { value: height },
      volume: { value: volume },
      length: { value: length },
      critair: { value: critair },
    }),
  );

  return (
    <Stack direction="column" size="full">
      <Title>Véhicules</Title>
      <DataTable headings={headings} data={data} loading={isLoading} maxWidth />
      <Pagination meta={vehiclesData?.headers} />
    </Stack>
  );
};

export default Vehicles;
