import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Loading } from '@tymate/elise/components';
import { ERRORS } from '@tymate/elise/utils';
import FranchiseForm from 'components/Forms/FranchiseForm';
import { getFranchise, updateFranchise } from 'api/franchises';
import { useAppState } from 'hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const EditFranchise = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { franchiseId } = useParams();
  const { notify } = useAppState();

  const { data: franchiseRequest, isLoading, isSuccess, isError } = useQuery(
    ['franchise', franchiseId],
    () => getFranchise({ franchiseId }),
  );

  const franchise = franchiseRequest?.data || {};

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation(
    values => updateFranchise(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['franchise', franchiseId]);
        notify('La franchise a été mise a jour.');
        history.push(`/franchises/${franchiseId}/details`);
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const handleUpdateFranchise = async values => {
    await mutateAsync({ franchiseId, payload: values });
  };

  return (
    <Modal
      variant="medium"
      title="Modifier une franchise"
      isOpen
      onRequestClose={() => history.push(`/franchises/${franchiseId}/details`)}
    >
      {isLoading && <Loading />}
      {isSuccess && (
        <FranchiseForm
          franchise={franchise}
          onSubmit={handleUpdateFranchise}
          onRequestClose={() =>
            history.push(`/franchises/${franchiseId}/details`)
          }
          isLoading={isLoadingMutation}
        />
      )}
      {isError && <span>Une erreur est survenue</span>}
    </Modal>
  );
};

export default EditFranchise;
