import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import { Button, Buttons } from '@tymate/elise/ui';
import { ERRORS } from '@tymate/elise/utils';
import TextField from '@tymate/elise/components/Fields/TextField';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
});

const InviteForm = ({ onSubmit, onCancel }) => {
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    resolver,
    defaultValues: {
      email: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField name="email" label="Adresse email" />
        <Buttons alignment="right">
          <Button type="button" variant="phantom" onClick={onCancel}>
            Annuler
          </Button>
          <Button
            type="submit"
            variant="primary"
            isLoading={methods.formState.isSubmitting}
          >
            Valider
          </Button>
        </Buttons>
      </form>
    </FormProvider>
  );
};

export default InviteForm;
