import React from 'react';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import SignUpForm from '@tymate/elise/components/SignUpForm';
import { useAuth } from 'hooks';
import queryString from 'query-string';
import { acceptInvitation, getTerms } from 'api/auth';
import { useQuery, useMutation } from 'react-query';

const Callbacks = ({ location, match }) => {
  const history = useHistory();
  const { onUpdate, onReset } = useAuth();
  const params = queryString.parse(location.search);
  const invitationToken = params?.invitation_token;

  const { data: terms } = useQuery(['terms'], () => getTerms(), {
    enabled: Boolean(invitationToken),
  });

  const { mutateAsync } = useMutation(values => acceptInvitation(values), {
    onSuccess: ({ data }) => {
      onUpdate(data);
      history.push('/');
    },
  });

  const handleSubmitSignupForm = async values => {
    const gtuId = (terms?.data || []).filter(({ kind }) => kind === 'gtu')[0]
      .id;

    const user = {
      ...values,
      invitationToken: invitationToken,
      gtuId,
    };

    await mutateAsync({ user });
  };

  useEffectOnce(() => {
    onReset();
  });

  return (
    <SignUpForm
      terms={terms?.data || []}
      location={location}
      match={match}
      onSubmit={handleSubmitSignupForm}
    />
  );
};

export default Callbacks;
