import React from 'react';
import { useEffectOnce } from 'react-use';
import { Route, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks';
import AuthLayout from '@tymate/elise/components/AuthLayout';
import SignUp from './SignUp';
import UpdatePassword from './UpdatePassword';
import background from 'images/background-auth.png';
import back from 'images/back.jpg';
import logo from 'images/logo.png';

const Callbacks = () => {
  const { onReset } = useAuth();
  const location = useLocation();

  useEffectOnce(() => {
    onReset();
  });

  return (
    <AuthLayout
      logo={logo}
      backgroundImage={background}
      sideImage={
        location.pathname.indexOf('/callback/invitation') === 0 && back
      }
    >
      <Route path="/callback/reset_password" component={UpdatePassword} />
      <Route path="/callback/invitations" component={SignUp} />
    </AuthLayout>
  );
};

export default Callbacks;
