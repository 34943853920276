import React from 'react';
import styled from 'styled-components';
import { MdPlace, MdEmail, MdPhone, MdEdit } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import { concatenate } from '@tymate/elise/utils';
import { Card, CardSubtitle, MainInfoItem } from '@tymate/elise/ui/card';
import { Button } from '@tymate/elise/components';
import { RawLink, Title } from '@tymate/elise/ui';

const Row = styled(Stack).attrs({
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
  gutterSize: 1,
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.375)};
`;

const Legend = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLighten};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const FranchiseDetails = ({ franchise }) => {
  return (
    <Card>
      <Stack direction="column" gutterSize={2} size="full">
        <Stack size="full" alignX="space-between">
          <Stack direction="column" gutterSize={0.5}>
            <Title>{franchise?.name}</Title>

            <MainInfoItem>
              <MdPlace size={24} />
              {concatenate(
                franchise?.address,
                franchise?.zipCode,
                franchise?.city,
              )}
            </MainInfoItem>
            <MainInfoItem>
              <MdEmail size={24} />
              {franchise?.email || '–'}
            </MainInfoItem>
            <MainInfoItem>
              <MdPhone size={24} />
              {franchise?.phoneNumber || '–'}
            </MainInfoItem>
          </Stack>

          <Stack direction="column" gutterSize={1.5}>
            <Button
              type="button"
              variant="outline"
              icon={<MdEdit />}
              as={RawLink}
              to={`/franchises/${franchise?.id}/details/modifier`}
            >
              Modifier la franchise
            </Button>
          </Stack>
        </Stack>

        <Stack
          size="full"
          alignX="space-between"
          alignY="flex-end"
          gutterSize={3}
        >
          <Stack direction="column" size="full" style={{ flex: 2 }}>
            <CardSubtitle>Informations</CardSubtitle>
            <Row>
              <div>SIREN :</div>
              <div>{franchise?.siren || '–'}</div>
            </Row>
            <Row>
              <div>SIRET :</div>
              <div>{franchise?.siret || '–'}</div>
            </Row>
            <Row>
              <div>N° TVA :</div>
              <div>{franchise?.tvaNumber || '–'}</div>
            </Row>
            <Row>
              <div>Rec Transport :</div>
              <div>{franchise?.recTransport || '–'}</div>
            </Row>
            <Row>
              <div>Rec Negoce :</div>
              <div>{franchise?.recNegoce || '–'}</div>
            </Row>
          </Stack>
          <Card style={{ flex: 1 }} height="auto" lightPadding>
            <Stack
              direction="column"
              alignY="center"
              gutterSize={0.5}
              size="full"
            >
              <Legend>Responsable</Legend>
              <CardSubtitle noMargin>
                {franchise?.manager?.firstName} {franchise?.manager?.lastName}
              </CardSubtitle>
              <MainInfoItem>
                <MdEmail size={24} />
                {franchise?.manager?.email || '–'}
              </MainInfoItem>
              <MainInfoItem>
                <MdPhone size={24} />
                {franchise?.manager?.phoneNumber || '–'}
              </MainInfoItem>
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </Card>
  );
};

export default FranchiseDetails;
