import React from 'react';
import styled, { css } from 'styled-components';
import { List, ButtonReset } from '@tymate/elise/ui';

const StepsList = styled(List)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing()};
`;

const Step = styled.li`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  position: relative;

  &:before,
  &:after {
    margin-top: ${({ theme }) => theme.spacing(0.75)};
  }

  ${({ isFirst, theme, isDone, isCurrent }) =>
    !isFirst &&
    css`
      &:before {
        margin-right: ${theme.spacing()};
        flex: 1;
        display: block;
        content: '';
        border-bottom: 1px solid
          ${isDone || isCurrent ? theme.primary : theme.separator};
      }
    `}

  ${({ isLast, theme, isDone }) =>
    !isLast &&
    css`
      &:after {
        margin-left: ${theme.spacing()};
        flex: 1;
        display: block;
        content: '';
        border-bottom: 1px solid ${isDone ? theme.primary : theme.separator};
      }
    `}
`;

const StepNumber = styled.span`
  display: flex;
  width: 1.5em;
  height: 1.5em;
  line-height: 1;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.separator};
  margin-right: ${({ theme }) => theme.spacing(0.5)};

  ${({ theme, isCurrent, isDone }) =>
    (isCurrent || isDone) &&
    `
      color: #fff;
      background-color: ${theme.primary}
    `}
`;

const StepLabel = styled.span`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(0.25)};
`;

const StepLink = styled(ButtonReset)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:disabled {
    color: inherit;
    cursor: default;
  }
`;

const Steps = ({ steps, current, onGoToStep }) => (
  <StepsList>
    {steps.map((step, index) => {
      const isCurrent = current === index + 1;
      const isDone = current > index + 1;

      return (
        <Step
          key={index}
          isFirst={index === 0}
          isLast={index === steps.length - 1}
          isDone={isDone}
          isCurrent={isCurrent}
        >
          <StepLink
            onClick={() => {
              if (index + 1 < current) {
                onGoToStep(index + 1);
              }
            }}
          >
            <StepNumber isCurrent={isCurrent} isDone={isDone}>
              {index + 1}
            </StepNumber>
            <StepLabel>{step}</StepLabel>
          </StepLink>
        </Step>
      );
    })}
  </StepsList>
);

export default Steps;
