import React, { useState } from 'react';
import { AuthContext } from 'contexts';
import { getMe } from 'api/auth';
import { useQuery, useQueryClient } from 'react-query';

const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  );
  const queryClient = useQueryClient();
  const { data: userData } = useQuery(['user', accessToken], getMe, {
    enabled: Boolean(accessToken),
    onError: () => {
      handleReset();
    },
  });
  const user = userData?.data;

  const handleUpdate = ({ accessToken, refreshToken }) => {
    setAccessToken(accessToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };

  const handleReset = () => {
    setAccessToken(null);
    localStorage.setItem('accessToken', '');
    localStorage.removeItem('draft');
    localStorage.setItem('refreshToken', '');
    queryClient.clear();
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        user,
        onReset: handleReset,
        onUpdate: handleUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
