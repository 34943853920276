import React from 'react';
import { useParams } from 'react-router-dom';
import { getFranchiseUsers } from 'api/franchises';
import DataTable from '@tymate/elise/components/DataTable';
import {
  ActionButton,
  Circle,
  Icon,
  IconAndText,
  RawLink,
  Title,
} from '@tymate/elise/ui';
import { FaUserCircle } from 'react-icons/fa';
import { Pagination } from '@tymate/elise/components/New';
import { useSearchParams } from '@tymate/elise/hooks';
import { useQuery } from 'react-query';
import { formatSort, getLabelFromOptionsList } from '@tymate/elise/utils';
import { MdRemoveRedEye } from 'react-icons/md';
import { tail } from 'lodash';
import { Stack } from '@tymate/margaret';
import Truncate from 'react-truncate';
import { Tooltip } from '@tymate/elise/components';

const Users = () => {
  const { franchiseId } = useParams();
  const [{ page = 1, sort, search }] = useSearchParams();

  const { data: usersData, isLoading } = useQuery(
    ['franchiseUsers', franchiseId, sort, page, search],
    () => {
      return getFranchiseUsers({
        franchiseId,
        params: {
          'filter[full_name]': search,
          'page[number]': page,
          'page[size]': 15,
          sort: formatSort(sort),
        },
      });
    },
  );

  const users = usersData?.data || [];

  const headings = [
    {
      slug: 'lastName',
      label: 'Nom du collaborateur',
    },
    {
      slug: 'email',
      label: 'Adresse email',
      cannotBeReordered: true,
    },
    {
      slug: 'phoneNumber',
      label: 'Téléphone',
      cannotBeReordered: true,
    },
    {
      slug: 'functions',
      label: 'Fonctions',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      label: '',
      cannotBeReordered: true,
    },
  ];

  const data = users.map(
    ({ firstName, lastName, email, phoneNumber, functions, id }) => ({
      lastName: {
        value: lastName,
        render: () => (
          <IconAndText>
            <Icon>
              <FaUserCircle />
            </Icon>
            {firstName} {lastName}
          </IconAndText>
        ),
      },
      email: { value: email },
      phoneNumber: { value: phoneNumber },
      functions: {
        value: functions?.name,
        render: () => {
          const functionsValue = tail(functions);
          const functionsName = functionsValue.map(value =>
            getLabelFromOptionsList(value),
          );
          return (
            <Stack gutterSize={0.25} alignX="space-between" size="full">
              <span>{getLabelFromOptionsList(functions?.[0])}</span>
              {Boolean(functionsName[0]) && Boolean(functions.length > 0) && (
                <Tooltip
                  variant="orange"
                  tip={
                    <Truncate lines={3}>
                      {functionsName.map((item, index) => (
                        <>{(index ? ', ' : '') + item}</>
                      ))}
                    </Truncate>
                  }
                >
                  <Circle>+{functionsName.length}</Circle>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
      actions: {
        render: () => (
          <ActionButton
            variant="isolated"
            hasNoMargin
            as={RawLink}
            to={`/franchises/${franchiseId}/ressources/collaborateurs/${id}`}
          >
            <MdRemoveRedEye />
          </ActionButton>
        ),
      },
    }),
  );

  return (
    <>
      <Title>Collaborateurs</Title>
      <DataTable
        isSearchable
        headings={headings}
        data={data}
        loading={isLoading}
      />
      <Pagination meta={usersData?.headers} />
    </>
  );
};

export default Users;
