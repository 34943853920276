import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminsList from './AdminsList';
import DeleteAdmin from './DeleteAdmin';
import InviteAdmin from './InviteAdmin';

const Admins = () => {
  return (
    <>
      <Switch>
        <Route
          path="/administrateurs/:userId/supprimer"
          component={DeleteAdmin}
        />
        <Route path="/administrateurs/inviter" component={InviteAdmin} />
      </Switch>
      <Switch>
        <Route path="/administrateurs" component={AdminsList} />
        <Redirect to="/administrateurs" />
      </Switch>
    </>
  );
};

export default Admins;
