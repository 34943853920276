import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Container } from '@tymate/elise/ui';
import EditFranchise from './EditFranchise';
import FranchiseInformation from './FranchiseInformation';
import FranchiseRessources from './Ressources';
import FranchiseProductionSites from './ProductionSites';
import SubHeader from 'components/SubHeader';
import FranchiseParameters from './FranchiseParameters';

const Franchise = () => {
  return (
    <>
      <SubHeader />
      <Container>
        <Switch>
          <Route
            path="/franchises/:franchiseId/details"
            component={FranchiseInformation}
          />

          <Route
            path="/franchises/:franchiseId/ressources"
            component={FranchiseRessources}
          />

          <Route
            path="/franchises/:franchiseId/sites-de-productions"
            component={FranchiseProductionSites}
          />

          <Route
            path="/franchises/:franchiseId/parametres"
            component={FranchiseParameters}
          />

          <Redirect to="/franchises/:franchiseId/details" />
        </Switch>
      </Container>
      <Switch>
        <Route
          path="/franchises/:franchiseId/details/modifier"
          component={EditFranchise}
        />
      </Switch>
    </>
  );
};

export default Franchise;
