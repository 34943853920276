import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import FranchisesList from './FranchisesList';
import CreateFranchise from './CreateFranchise';
import Franchise from './Franchise';

const Franchises = () => {
  return (
    <>
      <Switch>
        <Route path="/franchises/creer" exact component={CreateFranchise} />
      </Switch>
      <Switch>
        <Route path="/franchises/:franchiseId/*" component={Franchise} />
        <Route path="/franchises" component={FranchisesList} />
        <Redirect to="/franchises" />
      </Switch>
    </>
  );
};

export default Franchises;
