import React, { Fragment } from 'react';
import { DAYS, orderedDays } from '@tymate/elise/utils';
import { capitalize, orderBy } from 'lodash';
import styled, { css } from 'styled-components';
import { formatTime } from '@tymate/elise/utils';
import { Stack } from '@tymate/margaret';
import { MdAccessTime } from 'react-icons/md';
import { Subtitle } from '@tymate/elise/ui';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 6em) repeat(2, minmax(auto, 8em));
  grid-column-gap: ${({ theme }) => theme.spacing()};
  grid-row-gap: ${({ theme }) => theme.spacing(0.125)};

  ${({ variant }) =>
    variant === 'compact' &&
    css`
      font-size: 14px;
      grid-template-columns: minmax(auto, 5em) repeat(2, minmax(auto, 8em));
      grid-column-gap: ${({ theme }) => theme.spacing(0.5)};
    `}
`;

const Icon = styled.span`
  color: ${({ theme }) => theme.primary};
`;

const DayCell = styled.div`
  ${({ workingDay }) =>
    !Boolean(workingDay.closed) &&
    !Boolean(workingDay.openHours) &&
    !Boolean(workingDay.middleCloseHours) &&
    !Boolean(workingDay.middleOpenHours) &&
    !Boolean(workingDay.closeHours) &&
    css`
      color: ${({ theme }) => theme.textLighten};
    `}

  ${({ workingDay }) =>
    Boolean(workingDay.closed) &&
    css`
      color: ${({ theme }) => theme.textLighten};
    `}
`;

const TimesCell = styled.div`
  ${({ gridColumn }) =>
    Boolean(gridColumn) &&
    css`
      grid-column: ${gridColumn};
    `}

  ${({ isGreyedOut }) =>
    Boolean(isGreyedOut) &&
    css`
      color: ${({ theme }) => theme.textLighten};
    `}
`;

const DayOpeningHours = ({ workingDay = {}, variant, isUserInfos }) => {
  const formatSlot = ({ startAt, endAt }) =>
    `${formatTime(startAt)}${variant === 'compact' ? '-' : ' – '}${formatTime(
      endAt,
    )}`;
  const message = isUserInfos ? 'Non travaillé' : 'Fermé';

  const {
    closed,
    openHours,
    middleCloseHours,
    middleOpenHours,
    closeHours,
  } = workingDay;

  if (
    !closed &&
    !openHours &&
    !middleCloseHours &&
    !middleOpenHours &&
    !closeHours
  ) {
    return (
      <TimesCell gridColumn="span 2" isGreyedOut>
        {message}
      </TimesCell>
    );
  }

  if (closed) {
    return (
      <TimesCell gridColumn="span 2" isGreyedOut>
        {message}
      </TimesCell>
    );
  }

  if (!Boolean(middleOpenHours) && !Boolean(closeHours)) {
    return (
      <>
        <TimesCell>
          {formatSlot({ startAt: openHours, endAt: middleCloseHours })}
        </TimesCell>
        <TimesCell isGreyedOut>{message}</TimesCell>
      </>
    );
  }

  if (!Boolean(openHours) && !Boolean(middleCloseHours)) {
    return (
      <>
        <TimesCell isGreyedOut>{message}</TimesCell>
        <TimesCell>
          {formatSlot({
            startAt: middleOpenHours,
            endAt: closeHours,
          })}
        </TimesCell>
      </>
    );
  }

  if (Boolean(middleOpenHours) && Boolean(middleCloseHours)) {
    return (
      <>
        <TimesCell>
          {formatSlot({ startAt: openHours, endAt: middleCloseHours })}
        </TimesCell>
        <TimesCell>
          {formatSlot({ startAt: middleOpenHours, endAt: closeHours })}
        </TimesCell>
      </>
    );
  }

  return (
    <TimesCell gridColumn="span 2">
      {formatSlot({ startAt: openHours, endAt: closeHours })}
    </TimesCell>
  );
};

const WeekOpeningHours = ({ workingDays, variant, isUserInfos = false }) => {
  if (!Boolean(workingDays)) {
    return null;
  }

  return (
    <Stack
      gutterSize={0.5}
      direction={variant === 'compact' ? 'column' : 'row'}
    >
      {variant !== 'compact' && (
        <Icon>
          <MdAccessTime size={24} />
        </Icon>
      )}

      {variant === 'compact' && (
        <Subtitle noTopMargin>Horaires de travail</Subtitle>
      )}

      <Wrapper variant={variant}>
        {orderBy(workingDays || [], ({ day }) => orderedDays.indexOf(day)).map(
          (workingDay, index) => (
            <Fragment key={index}>
              <DayCell workingDay={workingDay}>
                {capitalize(DAYS[workingDay.day])}
              </DayCell>
              <DayOpeningHours
                workingDay={workingDay}
                variant={variant}
                isUserInfos={isUserInfos}
              />
            </Fragment>
          ),
        )}
      </Wrapper>
    </Stack>
  );
};

export default WeekOpeningHours;
