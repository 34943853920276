import React from 'react';
import styled from 'styled-components';
import { MdPlace, MdEmail, MdPhone, MdEdit } from 'react-icons/md';
import { Stack } from '@tymate/margaret';
import { concatenate } from '@tymate/elise/utils';
import { Card, CardSubtitle, MainInfoItem } from '@tymate/elise/ui/card';
import { Button, ToggleSwitch } from '@tymate/elise/components';
import { Title } from '@tymate/elise/ui';
import Vehicles from 'containers/Franchises/Franchise/ProductionSites/Vehicles';
import Users from 'containers/Franchises/Franchise/ProductionSites/Users';
import { useHistory } from 'react-router-dom';

const Row = styled(Stack).attrs({
  size: 'full',
  alignY: 'center',
  alignX: 'space-between',
  gutterSize: 1,
})`
  border-top: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.375)};
`;

const ColorPoint = styled.div`
  width: 16px;
  height: 16px;

  border-radius: 8px;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.success : theme.error};
  padding-left: ${({ theme }) => theme.spacing(0.25)};
`;

const Legend = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.textLighten};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const ProductionSiteDetails = ({
  productionSite,
  onUpdateProductionSite,
  isLoading,
}) => {
  const history = useHistory();

  return (
    <Stack direction="column" size="full" gutterSize={1} alignX="flex-start">
      <Card size="full">
        <Stack direction="column" gutterSize={2} size="full">
          <Stack size="full" alignX="space-between">
            <Stack direction="column" gutterSize={0.5}>
              <Title>{productionSite?.name}</Title>

              <MainInfoItem>
                <MdPlace size={24} />
                {concatenate(
                  productionSite?.address,
                  productionSite?.zipCode,
                  productionSite?.city,
                )}
              </MainInfoItem>
              <MainInfoItem>
                <MdEmail size={24} />
                {productionSite?.email || '–'}
              </MainInfoItem>
              <MainInfoItem>
                <MdPhone size={24} />
                {productionSite?.phoneNumber || '–'}
              </MainInfoItem>
            </Stack>

            {!productionSite?.firstProductionSite && (
              <Stack direction="column" gutterSize={1.5}>
                <Button
                  type="button"
                  variant="outline"
                  icon={<MdEdit />}
                  onClick={() =>
                    history.push(
                      `/franchises/${productionSite?.franchiseId}/sites-de-productions/${productionSite?.id}/modifier`,
                    )
                  }
                >
                  Modifier le site de production
                </Button>
                <Stack gutterSize={1} alignY="center">
                  <ColorPoint isOpen={productionSite?.open} />
                  <div>{`Site de production ${
                    productionSite?.open ? 'ouvert' : 'fermé'
                  }`}</div>
                  <ToggleSwitch
                    checked={productionSite?.open}
                    onChange={() => {
                      onUpdateProductionSite({
                        open: !productionSite?.open,
                      });
                    }}
                    disabled={isLoading}
                    id={`production-site-${productionSite?.id}-open`}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>

          <Stack
            size="full"
            alignX="space-between"
            alignY="flex-end"
            gutterSize={3}
          >
            <Stack direction="column" size="full" style={{ flex: 2 }}>
              <CardSubtitle>Informations</CardSubtitle>
              <Row>
                <div>SIREN :</div>
                <div>{productionSite?.siren || '–'}</div>
              </Row>
              <Row>
                <div>SIRET :</div>
                <div>{productionSite?.siret || '–'}</div>
              </Row>
              <Row>
                <div>N° TVA :</div>
                <div>{productionSite?.tvaNumber || '–'}</div>
              </Row>
              <Row>
                <div>Rec Transport :</div>
                <div>{productionSite?.recTransport || '–'}</div>
              </Row>
              <Row>
                <div>Rec Negoce :</div>
                <div>{productionSite?.recNegoce || '–'}</div>
              </Row>
            </Stack>
            <Card style={{ flex: 1 }} height="auto" lightPadding>
              <Stack
                direction="column"
                alignY="center"
                gutterSize={0.5}
                size="full"
              >
                <Legend>Responsable</Legend>
                <CardSubtitle
                  noMargin
                >{`${productionSite?.manager?.firstName} ${productionSite?.manager?.lastName}`}</CardSubtitle>
                <MainInfoItem>
                  <MdEmail size={24} />
                  {productionSite?.manager?.email || '–'}
                </MainInfoItem>
                <MainInfoItem>
                  <MdPhone size={24} />
                  {productionSite?.manager?.phoneNumber || '–'}
                </MainInfoItem>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Card>

      <Users />
      <Vehicles />
    </Stack>
  );
};

export default ProductionSiteDetails;
