import { post, get, put } from './';
import { CLIENT_ID } from '../constants';

export const signIn = payload =>
  post(`/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'password',
    scope: 'admin',
  });

export const signup = payload => post(`/v1/users`, payload);

export const refreshToken = payload =>
  post(`/oauth/token`, {
    ...payload,
    clientId: CLIENT_ID,
    grantType: 'refresh_token',
  });

export const postForgotPassword = payload =>
  post(`/v1/passwords`, { ...payload, clientId: CLIENT_ID });

export const updatePassword = payload =>
  put(`/v1/passwords`, {
    ...payload,
    clientId: CLIENT_ID,
  });

export const getPasswordCallback = () => get(`/v1/passwords/callback`);

export const getMe = () => get(`/v1/users/me`);

export const updateMe = payload => put(`/v1/users/me`, payload);

export const getTerms = () => get(`/v1/terms`);

export const acceptInvitation = payload =>
  put(`/v1/invitations`, {
    ...payload,
    clientId: CLIENT_ID,
  });
