import * as React from 'react';

const IcFileCSV = props => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>{'84898FDF-CE6B-4343-A492-ABD4C521191F'}</title>
    <g
      stroke="#EC732B"
      strokeWidth={1.25}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.376 19.378h-2.5 0c-.69 0-1.25-.56-1.25-1.25 0 0 0 0 0 0V1.878h0c0-.69.56-1.25 1.25-1.25h8.857c.332 0 .65.132.884.366l4.893 4.893h0c.234.234.366.552.366.884v3.857" />
      <path d="M16.876 6.878h-5 0c-.69 0-1.25-.56-1.25-1.25 0 0 0 0 0 0v-5M9.376 13.128h0a2.5 2.5 0 0 0-2.5 2.5v1.25h0a2.5 2.5 0 0 0 2.5 2.5M14.376 13.128h-1.25 0c-.69 0-1.25.56-1.25 1.25 0 1.875 2.5 1.875 2.5 3.75h0c0 .69-.56 1.25-1.25 1.25h-1.25M16.876 13.128v2.122h0c0 1.47.435 2.906 1.25 4.129h0a7.446 7.446 0 0 0 1.25-4.13v-2.12" />
    </g>
  </svg>
);

export default IcFileCSV;
