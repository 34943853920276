import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import TextField from '@tymate/elise/components/Fields/TextField';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import { Buttons, Grid, Cell } from '@tymate/elise/ui';
import { Button } from '@tymate/elise/components';
import Steps from '@tymate/elise/components/Steps';
import { ERRORS } from '@tymate/elise/utils';

const Subtitle = styled.p`
  font-weight: 500;
`;

const NAMES_BY_STEP = [
  ['name', 'legalName', 'address', 'zipCode', 'city', 'phoneNumber', 'email'],
  ['siren', 'siret', 'tvaNumber', 'axonautApiKey', 'recTransport', 'recNegoce'],
];

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(ERRORS.REQUIRED),
  legalName: Yup.string().required(ERRORS.REQUIRED),
  address: Yup.string().required(ERRORS.REQUIRED),
  zipCode: Yup.string().required(ERRORS.REQUIRED),
  city: Yup.string().required(ERRORS.REQUIRED),
  email: Yup.string().required(ERRORS.REQUIRED).email(ERRORS.EMAIL),
  phoneNumber: Yup.string().required(ERRORS.REQUIRED),
  siren: Yup.string()
    .required(ERRORS.REQUIRED)
    .min(9, 'Le SIREN doit contenir 9 caractères')
    .max(9, 'Le SIREN doit contenir maximum 9 caractères'),
  siret: Yup.string()
    .required(ERRORS.REQUIRED)
    .min(14, 'Le SIRET doit contenir 14 caractères')
    .max(14, 'Le SIRET doit contenir maximum 14 caractères'),
  tvaNumber: Yup.string().required(ERRORS.REQUIRED),
  recTransport: Yup.string().required(ERRORS.REQUIRED),
  recNegoce: Yup.string().required(ERRORS.REQUIRED),
  manager: Yup.object().shape({
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    phoneNumber: Yup.string().required(ERRORS.REQUIRED),
    email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
  }),
});

const ProductionSiteForm = ({
  onSubmit,
  productionSite,
  onRequestClose,
  isLoading,
}) => {
  const [step, setStep] = useState(1);
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      name: productionSite?.name || '',
      legalName: productionSite?.legalName || '',
      address: productionSite?.address || '',
      zipCode: productionSite?.zipCode || '',
      city: productionSite?.city || '',
      phoneNumber: productionSite?.phoneNumber || '',
      email: productionSite?.email || '',
      siren: productionSite?.siren || '',
      siret: productionSite?.siret || '',
      tvaNumber: productionSite?.tvaNumber || '',
      recTransport: productionSite?.recTransport || '',
      recNegoce: productionSite?.recNegoce || '',
      manager: {
        firstName: productionSite?.manager?.firstName || '',
        lastName: productionSite?.manager?.lastName || '',
        phoneNumber: productionSite?.manager?.phoneNumber || '',
        email: productionSite?.manager?.email || '',
      },
    },
  });

  const handleNextStep = async () => {
    const names = NAMES_BY_STEP[step - 1];

    await Promise.all(names.map(name => methods.trigger(name)));
    if (isEmpty(methods.formState.errors)) {
      setStep(step + 1);
    }
  };

  return (
    <>
      <Steps
        current={step}
        steps={['Infos générales', 'Infos complémentaires', 'Responsable']}
        onGoToStep={setStep}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {step === 1 && (
            <>
              <Grid>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="name" label="Nom" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField
                    disabled
                    name="legalName"
                    label="Dénomination légale"
                  />
                </Cell>

                <Cell sizes={{ default: 1 }}>
                  <TextField name="address" label="Adresse" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="zipCode" label="Code postal" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="city" label="Ville" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="phoneNumber" label="Numéro de téléphone" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="email" label="Adresse email" />
                </Cell>
              </Grid>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleNextStep}
                >
                  Valider
                </Button>
              </Buttons>
            </>
          )}

          {step === 2 && (
            <>
              <Grid>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField disabled name="siren" label="SIREN" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="siret" label="SIRET" />
                </Cell>

                <Cell sizes={{ default: 1 }}>
                  <TextField disabled name="tvaNumber" label="N° TVA" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="recTransport" label="rec Transport" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="recNegoce" label="rec Negoce" />
                </Cell>
              </Grid>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleNextStep}
                >
                  Valider
                </Button>
              </Buttons>
            </>
          )}

          {step === 3 && (
            <>
              <Subtitle>Responsable</Subtitle>
              <Grid>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.lastName" label="Nom" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.firstName" label="Prénom" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField
                    name="manager.phoneNumber"
                    label="Numéro de Téléphone"
                  />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.email" label="Adresse email" />
                </Cell>
              </Grid>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  Valider
                </Button>
              </Buttons>
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default ProductionSiteForm;
