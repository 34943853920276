import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Loading } from '@tymate/elise/components';
import { ERRORS } from '@tymate/elise/utils';
import ProductionSiteForm from 'components/Forms/ProductionSiteForm';
import { createProductionSite } from 'api/productionSites';
import { getFranchise } from 'api/franchises';
import { useAppState } from 'hooks';

const CreateProductionSite = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { franchiseId } = useParams();
  const { notify } = useAppState();

  const { data: response, isLoading } = useQuery(
    ['franchiseId', franchiseId],
    () =>
      getFranchise({
        franchiseId,
        params: {
          'page[number]': 1,
          'page[size]': 10,
        },
      }),
    {
      enabled: Boolean(franchiseId),
    },
  );

  const franchiseData = response?.data || [];

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation(
    values => createProductionSite(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['productionSites', franchiseId]);
        notify('Site de production créé.');
        history.goBack();
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const handleCreateProductionSite = async values => {
    await mutateAsync({ franchiseId, payload: values });
  };

  return (
    <Modal
      variant="medium"
      title="Ajouter un site de production"
      isOpen
      onRequestClose={() => history.goBack()}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <ProductionSiteForm
          productionSite={{
            legalName: franchiseData.legalName,
            siren: franchiseData.siren,
            tvaNumber: franchiseData.tvaNumber,
            siret: franchiseData.siret,
          }}
          onSubmit={handleCreateProductionSite}
          isLoading={isLoadingMutation}
          onRequestClose={() => history.goBack()}
        />
      )}
    </Modal>
  );
};

export default CreateProductionSite;
