import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  FunctionTag,
  HabilitationTag,
  LegendLighten,
  Subtitle,
  Title,
} from '@tymate/elise/ui';
import { Loading, Modal } from '@tymate/elise/components';
import { useQuery } from 'react-query';
import { MainInfoItem } from '@tymate/elise/ui/card';
import { MdEmail, MdPhone } from 'react-icons/md';
import WeekOpeningHours from 'components/WeekOpeningHours';
import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { getLabelFromOptionsList } from '@tymate/elise/utils';
import { getFranchiseUser } from 'api/franchises';

const WrapperTags = styled(Stack).attrs({ size: 'full', gutterSize: 1 })`
  display: flex;
  flex-wrap: wrap;
`;

const WrapperInfo = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
})`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

const UserDetail = () => {
  const history = useHistory();
  const { productionSiteId, franchiseId, userId } = useParams();

  const { data: userData, isLoading } = useQuery(
    ['userFranchise', franchiseId, userId],
    () => getFranchiseUser({ franchiseId, userId }),
  );

  const user = userData?.data || {};
  const functions = user?.functions || [];
  const { slopeClearance, staircaseClearance, intercomClearance } = user;

  const habilitations = [
    {
      habilitation: slopeClearance,
      label: 'Pente',
    },
    {
      habilitation: staircaseClearance,
      label: 'Escalier',
    },
    {
      habilitation: intercomClearance,
      label: 'Interphone',
    },
  ];

  if (isLoading) {
    return <Loading size={40} />;
  }

  return (
    <Modal
      isOpen
      onRequestClose={() =>
        history.push(
          productionSiteId
            ? `/franchises/${franchiseId}/sites-de-productions/${productionSiteId}`
            : `/franchises/${franchiseId}/ressources/collaborateurs`,
        )
      }
    >
      <Stack direction="column" size="full" gutterSize={0.5}>
        <Title>{`${user?.firstName} ${user?.lastName}`}</Title>
        <WrapperTags gutterSize={0.5}>
          {functions.map(job => (
            <FunctionTag>{getLabelFromOptionsList(job)}</FunctionTag>
          ))}
        </WrapperTags>
        <MainInfoItem>
          <MdEmail size={24} />
          {user?.email || '–'}
        </MainInfoItem>
        <MainInfoItem>
          <MdPhone size={24} />
          {user?.phoneNumber || '–'}
        </MainInfoItem>
        {user?.workingDays.length > 1 && (
          <WrapperInfo>
            <WeekOpeningHours
              variant="compact"
              isUserInfos
              workingDays={user?.workingDays}
            />
          </WrapperInfo>
        )}
        <WrapperInfo gutterSize={1}>
          <Stack direction="column">
            <Subtitle noTopMargin>Habilitations</Subtitle>
            <WrapperTags>
              {habilitations.map(
                ({ habilitation, label }) =>
                  habilitation && <HabilitationTag>{label} </HabilitationTag>,
              )}
            </WrapperTags>
          </Stack>
          <Stack>
            {Boolean(user?.otherClearances) && (
              <Stack size="full" direction="column">
                <LegendLighten>Autre(s) habilitation(s) :</LegendLighten>
                <p>{user?.otherClearances}</p>
              </Stack>
            )}
          </Stack>
        </WrapperInfo>
      </Stack>
    </Modal>
  );
};

export default UserDetail;
