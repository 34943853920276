import * as React from 'react';
import { theme } from '@tymate/elise/ui';

const IcBusiness = ({ color }, ...props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 3c1.05 0 1.918.82 1.994 1.851L12 5v2h8c1.05 0 1.918.82 1.994 1.851L22 9v10c0 1.05-.82 1.918-1.851 1.994L20 21H4c-1.05 0-1.918-.82-1.994-1.851L2 19V5c0-1.05.82-1.918 1.851-1.994L4 3h6zm9 6h-7v2h2v2h-2v2h2v2h-2v2h7c.51 0 .935-.388.993-.884L20 18v-8c0-.55-.45-1-1-1zm-9 8H8v2h2v-2zm-4 0H4v2h2v-2zm12-2v2h-2v-2h2zm-8-2H8v2h2v-2zm-4 0H4v2h2v-2zm12-2v2h-2v-2h2zm-8-2H8v2h2V9zM6 9H4v2h2V9zm4-4H8v2h2V5zM6 5H4v2h2V5z"
      fill={Boolean(color) ? theme[color] : '#ffffff'}
      fillRule="evenodd"
    />
  </svg>
);

export default IcBusiness;
