import React, { useContext } from 'react';
import { Stack } from '@tymate/margaret';
import { Container, Icon, RawLink, IconAndText } from '@tymate/elise/ui';
import { DataTable, Button } from '@tymate/elise/components';
import { getAdmins } from 'api/admins';
import { MdDelete, MdPersonAdd } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useSearchParams } from '@tymate/elise/hooks';
import { Pagination } from '@tymate/elise/components/New';
import { AuthContext } from 'contexts';

const AdminsList = () => {
  const [{ page = 1 }] = useSearchParams();
  const { user } = useContext(AuthContext);

  const { data: adminsData, isLoading } = useQuery(
    ['admins', page],
    () =>
      getAdmins({
        params: {
          'page[number]': page,
          'page[size]': 20,
        },
      }),
    {
      enabled: Boolean(user?.id),
    },
  );
  const admins = adminsData?.data || [];

  const headings = [
    {
      slug: 'lastName',
      label: "Nom de l'administrateur",
    },
    {
      slug: 'email',
      label: 'Adresse email',
      cannotBeReordered: true,
    },
    {
      slug: 'phoneNumber',
      label: 'Téléphone',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      label: '',
    },
  ];

  const data = admins.map(
    ({ email, firstName, lastName, phoneNumber, id }) => ({
      lastName: {
        value: lastName,
        render: () => (
          <IconAndText>
            <Icon>
              <FaUserCircle />
            </Icon>
            {firstName} {lastName}
          </IconAndText>
        ),
      },
      email: { value: email },
      phoneNumber: { value: phoneNumber },
      actions: {
        render: () =>
          id !== user.id && (
            <Icon
              variant="isolated"
              hasNoMargin
              as={RawLink}
              to={`/administrateurs/${id}/supprimer`}
            >
              <MdDelete />
            </Icon>
          ),
      },
    }),
  );

  return (
    <Container variant="main">
      <DataTable
        headings={headings}
        data={data}
        loading={isLoading}
        action={
          <Stack alignX="flex-end" size="full">
            <Button
              variant="primary"
              to="/administrateurs/inviter"
              as={RawLink}
              icon={<MdPersonAdd />}
            >
              Inviter un administrateur
            </Button>
          </Stack>
        }
      />
      <Pagination meta={adminsData?.headers} />
    </Container>
  );
};

export default AdminsList;
