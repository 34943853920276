import React from 'react';
import { useQuery } from 'react-query';
import { Container, Icon, IconAndText, RawLink } from '@tymate/elise/ui';
import DataTable from '@tymate/elise/components/DataTable';
import { Button } from '@tymate/elise/components';
import { Pagination } from '@tymate/elise/components/New';
import { getFranchises } from 'api/franchises';
import { MdChevronRight } from 'react-icons/md';
import { concatenate } from '@tymate/elise/utils';
import { Stack } from '@tymate/margaret';
import IcBusiness from 'images/IcBusiness';
import { useSearchParams } from '@tymate/elise/hooks';

const HEADINGS = [
  {
    slug: 'name',
    label: 'Nom de la franchise',
  },
  {
    slug: 'city',
    label: 'Ville',
    cannotBeReordered: true,
  },
  {
    slug: 'zipCode',
    label: 'Code Postal',
    cannotBeReordered: true,
  },
  {
    slug: 'manager',
    label: 'Responsable',
    cannotBeReordered: true,
  },
  {
    slug: 'email',
    label: 'Adresse email',
    cannotBeReordered: true,
  },
  {
    slug: 'actions',
    label: '',
  },
];

const FranchisesList = () => {
  const [{ page = 1 }] = useSearchParams();

  const { data: franchisesData, isLoading } = useQuery(
    ['franchises', page],
    () =>
      getFranchises({
        params: {
          'page[number]': page,
          'page[size]': 20,
        },
      }),
  );

  const franchises = franchisesData?.data || [];

  const data = franchises.map(
    ({ manager, name, city, zipCode, id, email }) => ({
      name: {
        value: name,
        render: () => (
          <IconAndText>
            <Icon>
              <IcBusiness color="primary" />
            </Icon>
            {name}
          </IconAndText>
        ),
      },
      city: { value: city },
      zipCode: { value: zipCode },
      manager: {
        value: concatenate(manager?.firstName || '', manager?.lastName || ''),
      },
      email: { value: email },
      actions: {
        render: () => (
          <Icon hasNoMargin variant="isolated">
            <MdChevronRight />
          </Icon>
        ),
      },
      path: `/franchises/${id}/details`,
    }),
  );

  return (
    <Container variant="main">
      <DataTable
        headings={HEADINGS}
        data={data}
        loading={isLoading}
        action={
          <Stack alignX="flex-end" size="full">
            <Button
              variant="primary"
              icon={<IcBusiness />}
              to="/franchises/creer"
              as={RawLink}
            >
              Ajouter une franchise
            </Button>
          </Stack>
        }
      />

      <Pagination meta={franchisesData?.headers} />
    </Container>
  );
};

export default FranchisesList;
