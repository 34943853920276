import styled, { css } from 'styled-components';
import { List, ButtonReset, media, RawLink } from './';
import { Stack } from '@tymate/margaret';

export const Tabs = styled(List)`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing()};
  max-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ${media.tablet`
    flex-wrap: wrap;
  `}

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const Tab = styled.li`
  display: block;
  box-shadow: inset 0 -1px ${({ theme }) => theme.separator};

  ${({ noBoxShadow }) =>
    noBoxShadow &&
    css`
      box-shadow: unset;
    `}

  + li {
    padding-left: ${({ theme }) => theme.spacing()};
  }
`;

export const TabNavButton = styled(ButtonReset)`
  background: transparent;
  border: 0;
  appearance: none;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  display: block;
  padding: ${({ theme }) => theme.spacing()};
  outline: none;
  cursor: pointer;
  min-width: 10em;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;

  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.primary};
      box-shadow: inset 0 -4px ${theme.primary};
    `};

  &.active {
    color: ${({ theme }) => theme.primary};
    box-shadow: inset 0 -4px ${({ theme }) => theme.primary};
  }

  ${media.tablet`
    width: 100%;
  `}
`;

export const TabNavLink = styled(TabNavButton)``.withComponent(RawLink);

export const PillTabs = styled(Stack).attrs({ alignY: 'center' })`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing()};
`;

export const PillTab = styled.li`
  display: block;
`;

export const PillTabNavButton = styled(ButtonReset)`
  display: block;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  appearance: none;
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  padding: ${({ theme }) => theme.spacing(0.375)}
    ${({ theme }) => theme.spacing()};
  cursor: pointer;
  width: max-content;
  text-align: center;
  transition: all 150ms ease;

  &.active {
    color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.primaryLighten};
  }

  ${({ variant, theme }) =>
    variant === 'add' &&
    css`
      color: ${theme.primary};
      border-color: transparent;
      background-color: ${theme.primaryLighten};
    `}

  &:hover {
    color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
  }
`;
