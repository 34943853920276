import React, { useContext } from 'react';
import {
  HeaderWrapper,
  HeaderLogo,
  HeaderLogoLink,
  MainNav,
  MainNavItem,
  MainNavItemLink,
} from '@tymate/elise/ui/layout';
import logo from 'images/logo.png';
import UserDropdown from '@tymate/elise/components/UserDropdown';
import { AuthContext } from 'contexts';
import { useLocation } from 'react-use';

const Header = () => {
  const { user, onReset } = useContext(AuthContext);
  const location = useLocation();

  return (
    <HeaderWrapper>
      <HeaderLogoLink to="/franchises">
        <HeaderLogo src={logo} />
      </HeaderLogoLink>

      <MainNav>
        <MainNavItem>
          <MainNavItemLink
            to="/franchises"
            exact
            className={
              location.pathname.indexOf('/franchises/') === 0 ? 'active' : ''
            }
          >
            Franchises
          </MainNavItemLink>
        </MainNavItem>
        <MainNavItem>
          <MainNavItemLink to="/administrateurs">
            Administrateurs
          </MainNavItemLink>
        </MainNavItem>
      </MainNav>
      <UserDropdown user={user} onReset={onReset} />
    </HeaderWrapper>
  );
};

export default Header;
