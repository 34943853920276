import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormError, FormField } from '@tymate/elise/ui/forms';
import { Select } from '@tymate/elise/components';

const SelectField = ({
  onChange = () => {},
  name,
  enablePersistentOptionLabel = true,
  ...props
}) => {
  const {
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useFormContext();
  const error = errors[name];
  const value = watch(name);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState();

  return (
    <FormField>
      <Select
        {...props}
        onChange={option => {
          if (props?.storeEntireOption) {
            setValue(name, option, { shouldDirty: true });
          } else {
            setValue(name, option?.value, { shouldDirty: true });
          }
          if (enablePersistentOptionLabel) {
            setSelectedOptionLabel(option?.label);
          }
          onChange(option);
          trigger(name);
        }}
        value={value}
        variant="input"
        size="auto"
        showOptionalLabel={props.showOptionalLabel}
        hasCompactDropList={props.hasCompactDropList}
        hasError={error}
        selectedOptionLabel={selectedOptionLabel}
        isField
      />
      {error && error.message && (
        <FormError role="alert">{error.message}</FormError>
      )}
    </FormField>
  );
};

export default SelectField;
