import React from 'react';
import { useHistory } from 'react-router-dom';
import UpdatePasswordForm from '@tymate/elise/components/UpdatePasswordForm';
import { useAuth } from 'hooks';
import queryString from 'query-string';
import { updatePassword } from 'api/auth';
import { useAppState } from 'hooks';

const Callbacks = ({ location }) => {
  const { notify } = useAppState();
  const history = useHistory();
  const { onUpdate } = useAuth();
  const params = queryString.parse(location.search);
  const resetPasswordToken = params?.reset_password_token;

  const handleUpdatePassword = async values => {
    try {
      const { data } = await updatePassword({
        user: {
          password: values.password,
          resetPasswordToken,
        },
      });
      const { accessToken, refreshToken } = data;
      onUpdate({ accessToken, refreshToken });
      notify('Mot de passe mis à jour.');
      history.push('/');
    } catch (err) {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  return <UpdatePasswordForm onSubmit={handleUpdatePassword} />;
};

export default Callbacks;
