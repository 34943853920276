import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Title } from '@tymate/elise/ui';
import { Button, Dropdown } from '@tymate/elise/components';
import {
  Tabs as SharedTabs,
  Tab as SharedTab,
  TabNavButton,
} from '@tymate/elise/ui/tabs';
import { Stack } from '@tymate/margaret';
import styled from 'styled-components';
import { PopoverItemButton, PopoverMenu } from '@tymate/elise/ui/popover';
import IcFileCSV from '@tymate/elise/icons/IcFileCSV';
import { MdFileUpload } from 'react-icons/md';
import {
  uploadResourcesFranchise,
  uploadClientsFranchise,
  uploadOrdersFranchise,
  getFranchise,
} from 'api/franchises';
import { useQuery } from 'react-query';
import { useAppState } from 'hooks';
import { useParams } from 'react-router-dom';

const Content = styled(Stack).attrs({
  alignY: 'center',
  alignX: 'space-between',
  size: 'full',
  direction: 'column',
})`
  background: #ffffff;
  box-shadow: inset 0 -1px ${({ theme }) => theme.separator};
`;

const Tabs = styled(SharedTabs)`
  transform: translate(0, 16px);
`;

const Tab = styled(SharedTab)`
  box-shadow: none;
`;

const SubHeader = () => {
  const { franchiseId } = useParams();
  const { notify } = useAppState();

  const { data: franchiseRequest } = useQuery(['franchise', franchiseId], () =>
    getFranchise({ franchiseId }),
  );

  const franchiseData = franchiseRequest?.data;

  const handleUploadFileResources = async file => {
    try {
      const payload = new FormData();
      payload.append('csv', file);
      await uploadResourcesFranchise(franchiseId, payload);
      notify('Fichier des gisements bien envoyé.');
    } catch (e) {
      notify('Une erreur est survenue, veuillez réessayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleUploadFileCollectionPoint = async file => {
    try {
      const payload = new FormData();
      payload.append('csv', file);
      await uploadClientsFranchise(franchiseId, payload);
      notify('Fichier des clients bien envoyé.');
    } catch (e) {
      notify('Une erreur est survenue, veuillez réessayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleUploadFileOrder = async file => {
    try {
      const payload = new FormData();
      payload.append('csv', file);
      await uploadOrdersFranchise(franchiseId, payload);
      notify('Fichier de commandes bien envoyé.');
    } catch (e) {
      notify('Une erreur est survenue, veuillez réessayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  const handleAddFilesEvent = async (e, type) => {
    const files = Array.from(e.target.files);
    const fileHandlers = {
      ressources: handleUploadFileResources,
      clients: handleUploadFileCollectionPoint,
      commandes: handleUploadFileOrder,
    };

    try {
      await Promise.all(files.map(file => fileHandlers[type]?.(file)));
    } catch (e) {
      notify('Une erreur est survenue, veuillez réessayer ultérieurement.', {
        type: 'error',
      });
    }
  };

  return (
    <Content>
      <Container variant="subHeader">
        <Stack
          size="full"
          alignY="center"
          alignX="space-between"
          paddingTop={1}
        >
          <Title style={{ marginBottom: 0 }}>{franchiseData?.legalName}</Title>
          <Dropdown
            trigger={
              <Button variant="outline" icon={<IcFileCSV />}>
                Import
              </Button>
            }
          >
            <PopoverMenu alignment="right">
              <PopoverItemButton as="label">
                <input
                  type="file"
                  onChange={e => handleAddFilesEvent(e, 'clients')}
                  style={{ display: 'none' }}
                />
                <MdFileUpload />
                CSV Clients
              </PopoverItemButton>
              <PopoverItemButton as="label">
                <input
                  type="file"
                  onChange={e => handleAddFilesEvent(e, 'ressources')}
                  style={{ display: 'none' }}
                />
                <MdFileUpload />
                CSV Gisements
              </PopoverItemButton>
              <PopoverItemButton as="label">
                <input
                  type="file"
                  onChange={e => handleAddFilesEvent(e, 'commandes')}
                  style={{ display: 'none' }}
                />
                <MdFileUpload />
                CSV Commandes
              </PopoverItemButton>
            </PopoverMenu>
          </Dropdown>
        </Stack>
        <Tabs>
          <Tab>
            <TabNavButton
              to={`/franchises/${franchiseId}/details`}
              as={NavLink}
            >
              Informations
            </TabNavButton>
          </Tab>
          <Tab>
            <TabNavButton
              to={`/franchises/${franchiseId}/ressources`}
              as={NavLink}
            >
              Ressources
            </TabNavButton>
          </Tab>
          <Tab>
            <TabNavButton
              to={`/franchises/${franchiseId}/sites-de-productions`}
              as={NavLink}
            >
              Sites de production
            </TabNavButton>
          </Tab>
          <Tab>
            <TabNavButton
              to={`/franchises/${franchiseId}/parametres`}
              as={NavLink}
            >
              Paramètres
            </TabNavButton>
          </Tab>
        </Tabs>
      </Container>
    </Content>
  );
};

export default SubHeader;
