import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '@tymate/elise/components';
import { inviteAdmin } from 'api/admins';
import { useAppState } from 'hooks';
import InviteForm from 'components/Forms/InviteForm';
import { useMutation, useQueryClient } from 'react-query';

const InviteAdmin = () => {
  const history = useHistory();
  const { notify } = useAppState();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(values => inviteAdmin(values), {
    onSuccess: () => {
      queryClient.invalidateQueries('admins');
      notify('Nouvel administrateur invité.');
      history.push('/administrateurs');
    },
    onError: () => {
      notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
        type: 'error',
      });
    },
  });

  const handleSubmitInviteForm = async values => {
    await mutateAsync({ user: values });
  };

  return (
    <Modal
      title="Inviter un administrateur"
      isOpen
      onRequestClose={() => history.push('/administrateurs')}
    >
      <InviteForm
        onCancel={() => history.push('/administrateurs')}
        onSubmit={handleSubmitInviteForm}
      />
    </Modal>
  );
};

export default InviteAdmin;
