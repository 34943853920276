import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { Modal, Loading } from '@tymate/elise/components';
import { ERRORS } from '@tymate/elise/utils';
import ProductionSiteForm from 'components/Forms/ProductionSiteForm';
import { getProductionSite, updateProductionSite } from 'api/productionSites';
import { useAppState } from 'hooks';

const EditProductionSite = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { franchiseId, productionSiteId } = useParams();
  const { notify } = useAppState();

  const { data: response, isLoading } = useQuery(
    ['productionSite', productionSiteId],
    () =>
      getProductionSite({
        franchiseId,
        productionSiteId,
        params: {
          'page[number]': 1,
          'page[size]': 10,
        },
      }),
  );

  const productionSite = response?.data || [];

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation(
    values => updateProductionSite(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['productionSite', productionSiteId]);
        queryClient.invalidateQueries(['productionSites', franchiseId]);
        notify('Site de production mise à jour.');
        history.push(
          `/franchises/${franchiseId}/sites-de-productions/${productionSiteId}`,
        );
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const handleUpdateProductionSite = async values => {
    await mutateAsync({ franchiseId, productionSiteId, payload: values });
  };

  return (
    <Modal
      variant="medium"
      title="Modifier un site de production"
      isOpen
      onRequestClose={() => history.goBack()}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <ProductionSiteForm
          productionSite={productionSite}
          onSubmit={handleUpdateProductionSite}
          isLoading={isLoadingMutation}
          onRequestClose={() => history.goBack()}
        />
      )}
    </Modal>
  );
};

export default EditProductionSite;
