import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from '@tymate/elise/components/AuthLayout';
import LoginForm from '@tymate/elise/components/LoginForm';
import background from 'images/background-auth.png';
import logo from 'images/logo.png';
import { signIn } from 'api/auth';
import { AuthContext } from 'contexts';

const Login = () => {
  const { onUpdate, accessToken } = useContext(AuthContext);

  const handleSignIn = async (values, { setStatus }) => {
    try {
      const { data } = await signIn(values);
      onUpdate(data);
    } catch (err) {
      setStatus(err?.response?.data?.error);
    }
  };

  if (accessToken) {
    return <Redirect to="/franchises" />;
  }

  return (
    <AuthLayout backgroundImage={background} logo={logo}>
      <LoginForm onSubmit={handleSignIn} />
    </AuthLayout>
  );
};

export default Login;
