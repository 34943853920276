import { post, get, put } from '.';

export const getProductionSites = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/production_sites`, { params });

export const getProductionSite = ({ franchiseId, productionSiteId, params }) =>
  get(
    `/v1/admin/franchises/${franchiseId}/production_sites/${productionSiteId}`,
    { params },
  );

export const createProductionSite = ({ franchiseId, payload }) =>
  post(`/v1/admin/franchises/${franchiseId}/production_sites`, payload);

export const updateProductionSite = ({
  franchiseId,
  productionSiteId,
  payload,
}) =>
  put(
    `/v1/admin/franchises/${franchiseId}/production_sites/${productionSiteId}`,
    payload,
  );

export const getProductionSiteVehicles = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/vehicles`, {
    params,
  });

export const getProductionSiteUsers = ({ franchiseId, params }) =>
  get(`/v1/admin/franchises/${franchiseId}/users`, { params });
