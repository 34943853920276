import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { theme, GlobalStyles } from '@tymate/elise/ui';
import App from './App';
import Login from 'containers/Auth/Login';
import Callbacks from 'containers/Auth/Callbacks/';
import ForgottenPassword from 'containers/Auth/ForgotPassword';
import AuthProvider from 'providers/AuthProvider';
import * as serviceWorker from './serviceWorker';
import AppProvider from './providers/AppProvider';
import { QueryClientProvider, QueryClient } from 'react-query';
import 'sanitize.css';
import { API_BASE, SENTRY_DSN, SENTRY_ENVIRONMENT } from './constants';
import { initSentry } from '@tymate/elise/utils/sentry';

export const history = createBrowserHistory();

initSentry(
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  API_BASE,
  history,
  'elise-backoffice',
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 20,
    },
  },
});

ReactDOM.render(
  <Suspense fallback={<div />}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <GlobalStyles />
          <AuthProvider>
            <Router history={history}>
              <Switch>
                <Route path="/callback/*" component={Callbacks} />
                <Route path="/connexion" component={Login} />
                <Route
                  path="/mot-de-passe-oublie"
                  component={ForgottenPassword}
                />
                <Route path="/*" component={App} />
              </Switch>
            </Router>
          </AuthProvider>
        </AppProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </Suspense>,
  document.getElementById('root'),
);

serviceWorker.unregister();
