import React from 'react';
import { useParams } from 'react-router-dom';
import { getFranchise } from 'api/franchises';
import { Container } from '@tymate/elise/ui';
import { Loading } from '@tymate/elise/components';
import FranchiseDetails from 'components/FranchiseDetails';
import { useQuery } from 'react-query';

const FranchiseInformation = () => {
  const { franchiseId } = useParams();

  const { data: franchiseRequest, isLoading } = useQuery(
    ['franchise', franchiseId],
    () => getFranchise({ franchiseId }),
  );

  const franchise = franchiseRequest?.data || {};

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container variant="main">
      <FranchiseDetails franchise={franchise}></FranchiseDetails>
    </Container>
  );
};

export default FranchiseInformation;
