import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Modal } from '@tymate/elise/components';
import { ERRORS } from '@tymate/elise/utils';
import FranchiseForm from 'components/Forms/FranchiseForm';
import { createFranchise } from 'api/franchises';
import { useAppState } from 'hooks';

const CreateFranchise = () => {
  const history = useHistory();
  const { notify } = useAppState();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    values => createFranchise(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('franchises');
        notify('Nouvelle franchise créée.');
        history.push('/franchises');
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const handleCreateFranchiseForm = async values => {
    await mutateAsync({
      ...values,
    });
  };

  return (
    <Modal
      variant="medium"
      title="Ajouter une franchise"
      isOpen
      onRequestClose={() => history.push('/franchises')}
    >
      <FranchiseForm
        onSubmit={handleCreateFranchiseForm}
        onRequestClose={() => history.push('/franchises')}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default CreateFranchise;
