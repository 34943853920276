import React from 'react';
import { DataTable } from '@tymate/elise/components';
import { useSearchParams } from '@tymate/elise/hooks';
import { Title } from '@tymate/elise/ui';
import { formatSort } from '@tymate/elise/utils';
import { Pagination } from '@tymate/elise/components/New';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getFranchiseExternalServiceProviders } from 'api/franchises';

const ExternalServiceProviders = () => {
  const { franchiseId } = useParams();
  const [{ page = 1, sort }] = useSearchParams();

  const { data: externalServiceProvidersData, isLoading } = useQuery(
    ['franchiseExternalServiceProviders', franchiseId, sort, page],
    () => {
      return getFranchiseExternalServiceProviders({
        franchiseId,
        params: {
          'page[number]': page,
          'page[size]': 15,
          sort: formatSort(sort),
        },
      });
    },
  );

  const externalServiceProviders = externalServiceProvidersData?.data || [];

  const headings = [
    {
      slug: 'name',
      label: 'Nom',
    },
    {
      slug: 'address',
      label: 'Adresse',
      cannotBeReordered: true,
    },
    {
      slug: 'siret',
      label: 'Siret',
      cannotBeReordered: true,
    },
    {
      slug: 'recNegoce',
      label: 'Rec Negoce',
      cannotBeReordered: true,
    },
    {
      slug: 'recTransport',
      label: 'Rec Transport',
      cannotBeReordered: true,
    },
    {
      slug: 'actions',
      cannotBeReordered: true,
    },
  ];

  const data = externalServiceProviders.map(
    ({ name, address, city, zipCode, siret, recNegoce, recTransport }) => ({
      name: {
        value: name,
      },
      address: { value: `${address || ''}, ${zipCode || ''}, ${city || ''}` },
      siret: { value: siret },
      recNegoce: { value: recNegoce },
      recTransport: { value: recTransport },
    }),
  );

  return (
    <>
      <Title>Prestataires externes</Title>
      <DataTable headings={headings} data={data} loading={isLoading} />
      <Pagination meta={externalServiceProvidersData?.headers} />
    </>
  );
};

export default ExternalServiceProviders;
