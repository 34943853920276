import React, { useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import styled, { css, useTheme } from 'styled-components';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import TextField from '@tymate/elise/components/Fields/TextField';
import useYupResolver from '@tymate/elise/hooks/useYupResolver';
import { Buttons, Grid, Cell, ButtonReset } from '@tymate/elise/ui';
import { Button } from '@tymate/elise/components';
import Steps from '@tymate/elise/components/Steps';
import { ERRORS } from '@tymate/elise/utils';
import { Stack } from '@tymate/margaret';
import { BiTrash } from 'react-icons/bi';
import { Text } from '@tymate/elise/ui/typography';

const AxonautCard = styled(Stack).attrs({
  direction: 'column',
  gutterSize: 0.5,
})`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.02);
  padding: ${({ theme }) => theme.spacing(1)};

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border: 1px solid ${theme.error};
      }
    `}
`;

const AxonautStack = styled(Stack).attrs({
  size: 'full',
  alignY: 'center',
  alignX: 'center',
  gutterSize: 0.5,
})``;

const AxonautTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const TextButton = styled(ButtonReset)`
  text-decoration: underline;
  color: ${({ theme }) => theme.primary};
`;

const Subtitle = styled.p`
  font-weight: 500;
`;

const NAMES_BY_STEP = [
  ['name', 'legalName', 'address', 'zipCode', 'city', 'phoneNumber', 'email'],
  ['siren', 'siret', 'tvaNumber', 'recTransport', 'recNegoce'],
];

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required(ERRORS.REQUIRED),
  legalName: Yup.string().required(ERRORS.REQUIRED),
  address: Yup.string().required(ERRORS.REQUIRED),
  zipCode: Yup.string().required(ERRORS.REQUIRED),
  city: Yup.string().required(ERRORS.REQUIRED),
  email: Yup.string().required(ERRORS.REQUIRED).email(ERRORS.EMAIL),
  phoneNumber: Yup.string().required(ERRORS.REQUIRED),
  siren: Yup.string()
    .required(ERRORS.REQUIRED)
    .min(9, 'Le SIREN doit contenir 9 caractères')
    .max(9, 'Le SIREN doit contenir maximum 9 caractères'),
  siret: Yup.string()
    .required(ERRORS.REQUIRED)
    .min(14, 'Le SIRET doit contenir 14 caractères')
    .max(14, 'Le SIRET doit contenir maximum 14 caractères'),
  tvaNumber: Yup.string().required(ERRORS.REQUIRED),
  axonautApiKeysAttributes: Yup.array(
    Yup.object({
      apiKey: Yup.string().required(ERRORS.REQUIRED),
    }),
  ).required(ERRORS.REQUIRED),
  recTransport: Yup.string().required(ERRORS.REQUIRED),
  recNegoce: Yup.string().required(ERRORS.REQUIRED),
  manager: Yup.object().shape({
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    phoneNumber: Yup.string().required(ERRORS.REQUIRED),
    email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
  }),
});

const AxonautTextField = ({ name, onDelete }) => {
  const theme = useTheme();

  return (
    <AxonautStack>
      <TextField name={name} />
      <div />
      <Button type="button" variant="phantom" onClick={onDelete}>
        <BiTrash size={20} color={theme.textLighten} />
      </Button>
    </AxonautStack>
  );
};

const FranchiseForm = ({ onSubmit, franchise, onRequestClose, isLoading }) => {
  const [step, setStep] = useState(1);
  const resolver = useYupResolver(VALIDATION_SCHEMA);
  const methods = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: {
      name: franchise?.name || '',
      legalName: franchise?.legalName || '',
      address: franchise?.address || '',
      zipCode: franchise?.zipCode || '',
      city: franchise?.city || '',
      phoneNumber: franchise?.phoneNumber || '',
      email: franchise?.email || '',
      siren: franchise?.siren || '',
      siret: franchise?.siret || '',
      tvaNumber: franchise?.tvaNumber || '',
      axonautApiKeysAttributes: franchise?.axonautApiKeys || [{ apiKey: '' }],
      recTransport: franchise?.recTransport || '',
      recNegoce: franchise?.recNegoce || '',
      manager: {
        firstName: franchise?.manager?.firstName || '',
        lastName: franchise?.manager?.lastName || '',
        phoneNumber: franchise?.manager?.phoneNumber || '',
        email: franchise?.manager?.email || '',
      },
    },
  });

  const handleNextStep = async () => {
    if (step === 2) {
      //Custom validation for axonaut keys
      const axonautApiKeysNames = fields.map(
        (value, index) => `axonautApiKeysAttributes[${index}].apiKey`,
      );
      await Promise.all(axonautApiKeysNames.map(name => methods.trigger(name)));
    }

    const names = NAMES_BY_STEP[step - 1];

    await Promise.all(names.map(name => methods.trigger(name)));
    if (isEmpty(methods.formState.errors)) {
      setStep(step + 1);
    }
  };

  const { append, remove, fields } = useFieldArray({
    control: methods.control,
    name: 'axonautApiKeysAttributes',
  });

  const hasAxonautError =
    Boolean(methods.formState.errors?.axonautApiKeysAttributes) &&
    methods.formState.errors?.axonautApiKeysAttributes?.length !== 0;

  return (
    <>
      <Steps
        current={step}
        steps={['Infos générales', 'Infos complémentaires', 'Responsable']}
        onGoToStep={setStep}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {step === 1 && (
            <Stack direction="column">
              <Grid>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="name" label="Nom" size="full" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="legalName" label="Dénomination légale" />
                </Cell>

                <Cell sizes={{ default: 1 }}>
                  <TextField name="address" label="Adresse" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="zipCode" label="Code postal" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="city" label="Ville" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="phoneNumber" label="Numéro de téléphone" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="email" label="Adresse email" />
                </Cell>
              </Grid>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleNextStep}
                >
                  Valider
                </Button>
              </Buttons>
            </Stack>
          )}

          {step === 2 && (
            <>
              <Grid>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="siren" label="SIREN" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="siret" label="SIRET" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="tvaNumber" label="N° TVA" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="recTransport" label="rec Transport" />
                </Cell>
                <Cell sizes={{ default: 1, tablet: 1 / 2 }}>
                  <TextField name="recNegoce" label="rec Negoce" />
                </Cell>
              </Grid>

              <AxonautCard
                direction="column"
                hasError={hasAxonautError}
                style={{ marginTop: '16px' }}
              >
                <AxonautTitle>Clé API Axonaut </AxonautTitle>
                {fields.map((value, index) => {
                  if (index === 0) {
                    return (
                      <TextField
                        key="axonautApiKeysAttributes[0]"
                        name="axonautApiKeysAttributes[0].apiKey"
                        style={{ marginBottom: '-16px' }}
                      />
                    );
                  } else {
                    return (
                      <AxonautTextField
                        key={`axonautApiKeysAttributes[${index}]`}
                        name={`axonautApiKeysAttributes[${index}].apiKey`}
                        onDelete={() => remove(index)}
                      />
                    );
                  }
                })}

                <TextButton
                  onClick={() => append({ apiKey: '' })}
                  type="button"
                >
                  Ajouter une clé
                </TextButton>
              </AxonautCard>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={handleNextStep}
                >
                  Valider
                </Button>
              </Buttons>
            </>
          )}

          {step === 3 && (
            <>
              <Subtitle>Responsable</Subtitle>
              <Grid>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.lastName" label="Nom" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.firstName" label="Prénom" />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField
                    name="manager.phoneNumber"
                    label="Numéro de Téléphone"
                  />
                </Cell>
                <Cell sizes={{ default: 1 }}>
                  <TextField name="manager.email" label="Adresse email" />
                </Cell>
              </Grid>

              <Buttons hasMarginTop alignment="right">
                <Button
                  type="button"
                  variant="phantom"
                  onClick={onRequestClose}
                >
                  Annuler
                </Button>
                <Button variant="primary" type="submit" isLoading={isLoading}>
                  Valider
                </Button>
              </Buttons>
            </>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default FranchiseForm;
