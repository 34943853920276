import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Buttons, RawLink } from '@tymate/elise/ui';
import { Button, Modal } from '@tymate/elise/components';
import { removeAdmin } from 'api/admins';
import { useAppState } from 'hooks';
import { useMutation, useQueryClient } from 'react-query';

const DeleteAdmin = () => {
  const history = useHistory();
  const { userId } = useParams();
  const { notify } = useAppState();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    values => removeAdmin(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('admins');
        notify('Administrateur supprimé.');
        history.push('/administrateurs');
      },
      onError: () => {
        notify('Une erreur est survenue, veuillez rééssayer ultérieurement.', {
          type: 'error',
        });
      },
    },
  );

  const onDeleteAdmin = async () => {
    await mutateAsync(userId);
  };

  return (
    <Modal
      isOpen
      title="Supprimer un administrateur"
      onRequestClose={() => history.push('/administrateurs')}
    >
      <p style={{ textAlign: 'center' }}>
        Êtes-vous sûr de vouloir supprimer cet utilisateur ?
      </p>
      <Buttons alignment="center">
        <Button variant="phantom" as={RawLink} to="/administrateurs">
          Annuler
        </Button>
        <Button variant="primary" isLoading={isLoading} onClick={onDeleteAdmin}>
          Valider
        </Button>
      </Buttons>
    </Modal>
  );
};

export default DeleteAdmin;
