import React, { useContext } from 'react';
import { Page, Content } from '@tymate/elise/ui/layout';
import { AuthContext } from 'contexts';
import Header from 'components/Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import useLocationBlocker from '@tymate/elise/hooks/useLocationBlocker';
import Franchises from 'containers/Franchises';
import Admins from 'containers/Admins';
import Profile from 'containers/Profile';

const App = () => {
  const { accessToken } = useContext(AuthContext);
  useLocationBlocker();

  if (!accessToken) {
    return <Redirect to="/connexion" />;
  }

  return (
    <Page>
      <Header />
      <Content>
        <Switch>
          <Route path="/administrateurs" component={Admins} />
          <Route path="/profil" component={Profile} />
          <Route path="/franchises" component={Franchises} />
          <Redirect to="/franchises" />
        </Switch>
      </Content>
    </Page>
  );
};

export default App;
