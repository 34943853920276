import React from 'react';
import { useParams } from 'react-router-dom';
import { getProductionSite, updateProductionSite } from 'api/productionSites';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import ProductionSiteDetails from 'components/ProductionSiteDetails';
import { useAppState } from 'hooks';
import { ERRORS } from '@tymate/elise/utils';
import { Loading } from '@tymate/elise/components';

const ProductionSite = () => {
  const queryClient = useQueryClient();
  const { franchiseId, productionSiteId } = useParams();
  const { notify } = useAppState();

  const { data: response, isLoading } = useQuery(
    ['productionSite', productionSiteId],
    () =>
      getProductionSite({
        franchiseId,
        productionSiteId,
      }),
    {
      enabled: productionSiteId && productionSiteId !== 'creer',
    },
  );
  const productionSite = response?.data || [];

  const { mutateAsync, isLoading: isLoadingMutation } = useMutation(
    values => updateProductionSite(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['productionSite', productionSiteId]);
        queryClient.invalidateQueries(['productionSites', franchiseId]);
        notify('Site de production mis à jour.');
      },
      onError: () => {
        notify(ERRORS.COMMON, {
          type: 'error',
        });
      },
    },
  );

  const handleUpdateProductionSite = async values => {
    await mutateAsync({
      franchiseId,
      productionSiteId,
      payload: values,
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ProductionSiteDetails
      productionSite={productionSite}
      onUpdateProductionSite={handleUpdateProductionSite}
      isLoading={isLoadingMutation}
    />
  );
};

export default ProductionSite;
